import * as React from "react";
import { Link } from "gatsby";

import Seo from "../components/seo";
import { getLink } from "../components/helper";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  const { siteUrl, pathPrefix } = useSiteMetadata();

  return (
    <>
      <div className="mb-4 tttest bg-left-top bg-cover overflow-hidden relative">
        <div className="bg-gradient-to-t from-black to-transparent absolute bottom-0 left-0 w-[100%] h-[600px] overflow-hidden"></div>

        <div class="custom-shape-divider-top-1661709281">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-top-1661709281 header-shape--high-left">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-top-1661716876">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="header-shape--high-right">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-top-1661710634">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div className="max-w-5xl mx-auto px-10 lg:px-20 flex flex-col text-2xl sm:text-4xl md:text-6xl lg:text-7xl tracking-tight font-semibold gap-10 items-start h-[70vh] sm:h-[65vh] md:h-[85vh] justify-end relative">
          <div className="w-[30%] min-w-[200px] z-50 ">
            <StaticImage
              src="../images/logo.svg"
              alt=""
              width={250}
              loading="eager"
              fadeIn={false}
              placeholder={"none"}
              durationFadeIn={0}
              placeholderStyle={{ visibility: "hidden" }}
            />
          </div>

          <h1 className="text-white z-50 mx-auto">
            ГОДОВОЙ ОТЧЕТ Акционерного общества «МОСГАЗ» за 2021 год
          </h1>
        </div>
      </div>
      <div
        className="indexList max-w-5xl mx-auto text-l md:text-xl lg:text-2xl px-10
      pl-10 sm:pl-20 md:pl-24 lg:pl-32 mt-8 md:mt-10"
      >
        <div className="indexList-item">
          <Link to="/part-0">ОБРАЩЕНИЕ ГЕНЕРАЛЬНОГО ДИРЕКТОРА ОБЩЕСТВА</Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-1">
            <span className="item-orange">РАЗДЕЛ 1.</span> ОБЩИЕ СВЕДЕНИЯ
          </Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-2">
          <span className="item-orange">РАЗДЕЛ 2.</span> ОСНОВНЫЕ ПРОИЗВОДСТВЕННЫЕ ПОКАЗАТЕЛИ
          </Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-3"><span className="item-orange">РАЗДЕЛ 3.</span> ИНВЕСТИЦИОННАЯ ДЕЯТЕЛЬНОСТЬ</Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-4">
          <span className="item-orange">РАЗДЕЛ 4.</span> ОСНОВНЫЕ ПОКАЗАТЕЛИ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ
          </Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-5"><span className="item-orange">РАЗДЕЛ 5.</span> КОРПОРАТИВНОЕ УПРАВЛЕНИЕ</Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-6">
          <span className="item-orange">РАЗДЕЛ 6.</span> ИНФОРМАЦИЯ ОБ ИМУЩЕСТВЕННОМ КОМПЛЕКСЕ
          </Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-7"><span className="item-orange">РАЗДЕЛ 7.</span> КАДРОВАЯ И СОЦИАЛЬНАЯ ПОЛИТИКА</Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-8">
          <span className="item-orange">РАЗДЕЛ 8.</span> ПРОИЗВОДСТВЕННАЯ БЕЗОПАСНОСТЬ И ЭКОЛОГИЧЕСКАЯ
            ОТВЕТСТВЕННОСТЬ
          </Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-9"><span className="item-orange">РАЗДЕЛ 9.</span> ОСВЕЩЕНИЕ ДЕЯТЕЛЬНОСТИ АО «МОСГАЗ»</Link>
        </div>

        <div className="indexList-item">
          <Link to="/part-10"><span className="item-orange">РАЗДЕЛ 10.</span> СПРАВОЧНАЯ ИНФОРМАЦИЯ</Link>
        </div>

        <div className="indexList-item">
          <Link to="/app-1">ПРИЛОЖЕНИЕ 1</Link>
        </div>

        <div className="indexList-item">
          <Link
            to={getLink({
              siteUrl,
              pathPrefix,
              filename: "/mos-gaz.accounting.statements.2021.pdf",
            })}
            native={true}
          >
            ПРИЛОЖЕНИЕ 2 (PDF) Бухгалтерская отчётность
          </Link>
        </div>
      </div>
      <footer className="footer relative">
        <div class="custom-shape-divider-bottom-1661706721 footer-shape-first">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 892.25 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661706721 footer-shape--low-left">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 892.25 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661707572">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661707572 footer-shape--low-right">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <p className="content-center absolute right-12 bottom-0 font-semibold">АО «МОСГАЗ» &copy; 2022</p>
      </footer>
    </>
  );
};

export default IndexPage;

export const Head = () => <Seo />;
